import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { VoiService } from 'src/app/services/voi-list';
import { MatCheckboxModule } from '@angular/material/checkbox';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css'],
})
export class SendEmailDialogComponent implements OnInit {
  action: string;
  dialogData: any;
  document: any;
  isLoading: boolean = true;
  addNewEmail: boolean = false;
  isInvalidEmail: boolean = false;
  emailResDefaultLength: number | null= null;
  newEmailText: string = '';
  leadEmails: string[] = [];
  emails: string[] = [];
  user_sequence_id = '';
  leadId = '';
  document_url = '';
  createdId = '';
  getSendEmailPayLoad = {
    body: {
      email_list: [],
      lead_verification_sequence_id: '',
      document_url: '',
      lead_notification_email_list:[""]
    },
    header: {
      version: '1.0',
      created_by: '',
      request_id: '',
      message_name: 'email_send-C',
      message_type: 'Q',
      message_uuid: '',
      correlation_uuid: '',
      created_timestamp: '2020-01-01000000.000',
      service_completion_status_code: '',
    },
  };

  constructor(
    private elementRef: ElementRef,
    public dialogRef: MatDialogRef<SendEmailDialogComponent>,
    private voiServiceData: VoiService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.dialogData = { ...data };
    this.action = this.dialogData.action;
    this.leadEmails = this.dialogData.emails;
    this.emailResDefaultLength= this.dialogData.emails.length
    this.user_sequence_id = this.dialogData.id;
    this.leadId = this.dialogData.leadId;
    this.document_url = this.dialogData.document_url;
  }

  ngOnInit(): void {}

  newEmail() {
    this.addNewEmail = true;
  }

  dumpNewEmail() {
    if(this.newEmailText){
      this.emails.push(this.newEmailText);
      this.addNewEmail = false;
      this.newEmailText = '';
    }
  }

  onInputChange(newValue: string) {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!emailPattern.test(newValue)) {
      this.isInvalidEmail = true;
    }else{
      this.isInvalidEmail = false;
    }
}
  async sendEmail() {
    if(!this.addNewEmail){
      this.isLoading = true;
      this.createdId = localStorage.getItem('currentUser') || '';
      let data = JSON.parse(this.createdId);
      let currentSupportId = data[0].current_support_id || '';
      let sendEmailPayLoad: any = this.getSendEmailPayLoad;
      sendEmailPayLoad.body.lead_notification_email_list=this.leadEmails;
      sendEmailPayLoad.body.email_list = this.emails;
      sendEmailPayLoad.header.created_by = currentSupportId;
      // sendEmailPayLoad.body.user_sequence_id = this.user_sequence_id;
      sendEmailPayLoad.body.lead_verification_sequence_id = this.leadId;
      sendEmailPayLoad.body.document_url = this.document_url;
      let parseResponse: any = await this.voiServiceData.sendEmailVoi(
        sendEmailPayLoad
      );
      if (parseResponse) {
        this.isLoading = false;
      }
      location.reload();
    }
  }


  validateAndDumpNewEmail() {
    // Add your email validation logic here
    // For example, using a simple regex pattern
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!this.newEmailText || !emailPattern.test(this.newEmailText)) {
        this.isInvalidEmail = true;
    } else {
        this.dumpNewEmail();
        this.isInvalidEmail = false;
    }
}
  removeEmail(index) {
   this.emails.splice(index, 1);
  }

  delete() {
    this.dialogRef.close({ event: this.action });
  }
  cancel() {
    this.dialogRef.close();
  }
}
// export class CheckboxReactiveFormsExample {
//   toppings = this._formBuilder.group({
//     pepperoni: false,
//     extracheese: false,
//     mushroom: false,
//   });

//   constructor(private _formBuilder: FormBuilder) {}
// }
