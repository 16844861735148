import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
  writeBatch,
} from "firebase/firestore";
import React, { useContext, useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Stack from "react-bootstrap/Stack";
import { styles } from "../Custom";
import "../Custom.css";
import { auth, db } from "../firebase";
import Chats from "./Chats";
// import UserLastMessage from "./UserLastMessage";
import { Buffer } from "buffer";
import * as CryptoJS from "crypto-js";
import moment from "moment/moment";
import { ChatContext } from "../Context/ChatContext";
import UnreadCount from "./UnreadCount";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";

const UserList = () => {
  const crypto = require("crypto-browserify");
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || "");
  const supportID = currentUser[0].current_support_id;
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        firebaseUserSignIn();
      }
    });
    return () => unsubscribe();
  }, []);

  const firebaseUserSignIn = async () => {
    signInAnonymously(auth)
      .then((response) => {
        // Signed in..
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  };
  /* Encrypt the Message Content */
  const encryptChat = (text: any) => {
    const encryptSecretKey = "@SiMBA.InSuRAnCE";
    const chatEncryptionSecretKey =
      "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";
    const mdString = crypto
      .createHash("md5")
      .update(
        Buffer.from(chatEncryptionSecretKey).toString("ASCII"),
        0,
        chatEncryptionSecretKey.length
      )
      .digest("hex");
    const substr = mdString.substring(0, 16);
    const CHATKEY = CryptoJS.enc.Utf8.parse(substr);
    const CHATIV = CryptoJS.enc.Utf8.parse(mdString.substring(16));
    var encrypted = CryptoJS.AES.encrypt(text, CHATKEY, {
      keySize: 128 / 8,
      iv: CHATIV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  };
  /* Decrypt the Message content */
  const decryptMessage = (stringds?: string | null) => {
    if (!stringds) {
      return;
    }

    const finalKey = getDecryptedChatMessage(stringds);
    return finalKey;
  };

  const chatEncryptionSecretKey =
    "asdfghjklqwertyuiopzxcvbnmasdfgh@YOLOH.InSuRAnCE";

  function getDecryptedChatMessage(encryptedText) {
    const { key, iv } = createChatPayloadKeys();
    return decryptChatPayload(encryptedText, { key, iv });
  }

  function createChatPayloadKeys() {
    let encryptSecretKey = chatEncryptionSecretKey;
    let mdString = crypto
      .createHash("md5")
      .update(
        Buffer.from(encryptSecretKey).toString("ASCII"),
        0,
        encryptSecretKey.length
      )
      .digest("hex");
    let key = CryptoJS.enc.Utf8.parse(mdString.substring(0, 16));
    let iv = CryptoJS.enc.Utf8.parse(mdString.substring(16));
    return { key, iv };
  }

  function decryptChatPayload(textToDecrypt, keyData) {
    try {
      let decrypted = CryptoJS.AES.decrypt(textToDecrypt, keyData.key, {
        iv: keyData.iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    } catch (error) {
      console.error("Decryption error:", error);
      return null;
    }
  }

  //Check Active Tab for User selected
  const [active, setActive] = useState("");
  const [currentType, setCurrentType] = useState("");
  const [lastMsg, setMyLastMsg] = useState([]);
  const selectedUserType = "";
  const pendingUserCount = 0;
  let activeMenu = localStorage.getItem("activeMenu");
  let isPendingUser = false;
  let isMyUser = false;
  let isIdleUser = false;

  const onSetActiveMenuItem = (item: any) => {
    localStorage.setItem("activeMenu", item);
    if (item !== active || item !== "" || activeMenu !== "") {
      setActive(item);
      activeMenu = item;
    } else {
      setActive(""); // handle click on currently active item
      activeMenu = "";
    }
  };

  // /* Collection Reference */
  const colRef = collection(db, "dev_users");
  /* Code for Fetching MyUser pending status */
  const [myuser, setMyUsers] = useState([]);
  /* Code for Pending MyUser pending status */
  const [pendingUser, setPendingUsers] = useState([]);
  /* Code for Idle MyUser pending status */
  const [idleUser, setIdleUsers] = useState([]);

  const [activeClass, selectUserActive] = useState();
  const [chatUser, setChatUser] = useState();
  const [msgs, setMsgs] = useState([]);

  const { dispatch } = useContext(ChatContext);
  //Dispatch the Chat Context

  const selectUser = async (user: any) => {
    let txt = document.getElementById("send-msg-txt");
    if (txt) {
      txt.style.display = "block";
    }
    localStorage.setItem("scroll", "false");

    const userArr = {
      id: user.id,
      user,
    };

    localStorage.setItem("selectedUser", JSON.stringify(userArr));
    setChatUser(user);
    selectUserActive(user.id);
    let supportInfo = {
      support_read: true,
    };

    dispatch({ type: "CHANGE_USER", payload: user });
    const batch = writeBatch(db);
    const data = await collection(db, `dev_users`, `${user?.id}/support_chat`);

    const querySnapshot = await getDocs(data);

    querySnapshot.forEach((doc) => {
      if (doc.data().support_read === false) {
        batch.update(doc.ref, { support_read: true });
      }
    });

    batch.commit();
  };

  if (localStorage.getItem("activeMenu") !== "") {
    const user = localStorage.getItem("activeMenu");
  }

  let localUser = "";
  if (localStorage.getItem("selectedUser") !== null) {
    localUser = JSON.parse(localStorage.getItem("selectedUser") || "").id;
  }
  const setUserMessage = async (user: any) => {
    const msgRef = collection(db, "dev_users", `${user.id}`, "support_chat");
    const q = query(msgRef, orderBy("timestamp", "asc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      let msgs: any = [];
      querySnapshot.forEach((doc) => {
        msgs.push(doc.data());
      });
      setMsgs(msgs);
    });
    return () => unsubscribe();
  };

  /*-----------------Code START for REAL TIME Users----------------- */

  //   const supportID = currentUser[0].current_support_id;
  // const currentUser = JSON.parse(localStorage.getItem("currentUser") || "");

  const [myusercount, setMyuserCount] = useState(0);

  useEffect(() => {
    document.title = `Yoloh Chat`;
    let totalMyUserCount = 0;
    const myuserList: any = [];
    const userQuery = query(
      colRef,
      where("status", "==", "assigned"),
      where("current_support_id", "==", supportID)
    );
    const unsubscribe = onSnapshot(userQuery, (querySnapshot) => {
      let tempUsers: any = [];
      querySnapshot.forEach((doc) => {
        tempUsers.push(doc.data());
        // getLastMessage(doc.data());
      });
      setMyUsers(tempUsers);

      totalMyUserCount = querySnapshot.size;
      setMyuserCount(totalMyUserCount);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const [pendingcount, setPendingCount] = useState(0);

  useEffect(() => {
    const pendingUserList: any = [];
    let totalPendingUser = 0;
    const countDataArr = [];
    const pendingQuery = query(colRef, where("status", "==", "waiting"));
    const unsub = onSnapshot(pendingQuery, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        pendingUserList.push(doc.data());
      });
      pendingUserList.sort(function (x: any, y: any) {
        return y.last_message_timestamp - x.last_message_timestamp;
      });
      totalPendingUser = querySnapshot.size;

      setPendingUsers(pendingUserList);
      setPendingCount(totalPendingUser);
    });

    return () => unsub();
  }, [pendingUser]);

  const [idleCount, SetIdleCount] = useState(0);

  useEffect(() => {
    const idleUserList: any = [];
    const idleQuery = query(colRef, where("status", "==", "idle"));
    let totalIdleUser = 0;
    const unsub = onSnapshot(idleQuery, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        idleUserList.push(doc.data());
      });
      idleUserList.sort(function (x: any, y: any) {
        return y.last_message_timestamp - x.last_message_timestamp;
      });
      //set State for Pending user
      totalIdleUser = querySnapshot.size;
      setIdleUsers(idleUserList);
      SetIdleCount(totalIdleUser);
    });

    return () => unsub();
  }, [idleUser]);

  return (
    // leave it for now
    <>
      <Container style={{ width: "100%", maxWidth: "100%" }}>
        <Row>
          <Col
            xs={4}
            md={4}
            style={{ display: "flex", padding: 0, background: "#80808017" }}
          >
            <Col
              xs={12}
              md={12}
              style={{ padding: 0, border: "3px solid #ffb600" }}
            >
              <Stack
                direction="horizontal"
                className="stack d-flex align-item-center justify-content-between"
                style={{
                  textAlign: "center",
                  borderBottom: "3px solid #ffb600",
                  padding: "0px",
                  fontSize: "large",
                  fontWeight: "500",
                }}
                gap={4}
              >
                <div
                  onClick={() => onSetActiveMenuItem("myusers")}
                  style={
                    active === "myusers" || activeMenu == "myusers"
                      ? styles.usertypeactive
                      : styles.usertype
                  }
                  id="myuser"
                >
                  My Users ({myusercount})
                </div>
                <div
                  onClick={() => onSetActiveMenuItem("pending")}
                  style={
                    active === "pending" || activeMenu == "pending"
                      ? styles.usertypeactive
                      : styles.usertype
                  }
                  id="pending"
                >
                  Pending ({pendingcount})
                </div>
                <div
                  onClick={() => onSetActiveMenuItem("idle")}
                  style={
                    active === "idle" || activeMenu == "idle"
                      ? styles.usertypeactive
                      : styles.usertype
                  }
                  id="idleuser"
                >
                  Idle ({idleCount})
                </div>
              </Stack>

              <div className="d-flex align-item-center w-100">
                <ListGroup
                  as="ul"
                  className="myuserswrapper"
                  style={
                    active === "myusers" || activeMenu == "myusers"
                      ? {
                          height: "calc(100vh - 120px)",
                          overflowY: "auto",
                          color: "#fff",
                          width: "100%",
                          padding: "5px",
                        }
                      : {
                          height: "calc(100vh - 120px)",
                          overflowY: "auto",
                          display: "none",
                          color: "black",
                          padding: "5px",
                        }
                  }
                >
                  {myuser
                    .sort((x: any, y: any) => {
                      return (
                        y.last_message_timestamp - x.last_message_timestamp
                      );
                    })
                    .map((myuserData: any, index) => {
                      return (
                        <ListGroup.Item
                          as="li"
                          className={
                            activeClass == myuserData.id ||
                            localUser == myuserData.id
                              ? "d-flex justify-content-between align-items-start active  w-100"
                              : "d-flex justify-content-between align-items-start w-100"
                          }
                          style={
                            activeClass == myuserData.id ||
                            localUser == myuserData.id
                              ? {
                                  marginBottom: "5px",
                                  borderRadius: "10px",
                                  backgroundColor: "#007bff30",
                                  border: "2px solid #007bff",
                                }
                              : { marginBottom: "5px", borderRadius: "10px" }
                          }
                          key={index}
                          onClick={() => selectUser(myuserData)}
                        >
                          <span
                            className="profileImage"
                            style={{
                              fontFamily: "Arial, Helvetica, sans-serif",
                              width: "3rem",
                              height: "3rem",
                              borderRadius: "50%",
                              background: " #ffb600",
                              fontSize: "1rem",
                              color: "#000",
                              textAlign: "center",
                              lineHeight: "3rem",
                              display: "inline-table",
                              margin: "0px",
                              marginRight: "5px",
                            }}
                          >
                            {myuserData.user_name
                              ? (decryptMessage(myuserData.user_name) || "")
                                  .split(" ")
                                  .slice(0, 2)
                                  .map((word) => word[0])
                                  .join("")
                                  ?.toString()
                                  .toUpperCase()
                              : ""}
                          </span>
                          <div
                            className="namewrapper"
                            style={{
                              marginRight: "auto",
                              marginLeft: "0.5rem",
                              color: "black",
                            }}
                          >
                            <div className="fw-bold">
                              {decryptMessage(myuserData.user_name)}
                            </div>
                            {myuserData?.last_message == "image" ? (
                              <label>Image</label>
                            ) : myuserData?.last_message == "PDF" ? (
                              <label>PDF</label>
                            ) : decryptMessage(myuserData?.last_message || "")
                                ?.length ? (
                              decryptMessage(myuserData?.last_message || "")!
                                .length >= 30 ? (
                                decryptMessage(
                                  myuserData?.last_message || ""
                                )!.substring(0, 30) + "..."
                              ) : (
                                decryptMessage(myuserData?.last_message || "")
                              )
                            ) : (
                              decryptMessage(myuserData?.last_message || "")
                            )}
                          </div>
                          <div
                            className="timestamp"
                            style={styles.timestamp}
                            key={index + 5}
                          >
                            <span>
                              {moment(
                                myuserData.last_message_timestamp
                              ).fromNow()}
                            </span>
                            <UnreadCount
                              key={index + 2001}
                              userid={myuserData.id}
                            ></UnreadCount>
                          </div>
                          {}
                        </ListGroup.Item>
                      );
                    })}
                </ListGroup>

                <ListGroup
                  as="ul"
                  key={1}
                  style={
                    active === "pending" || activeMenu == "pending"
                      ? {
                          height: "calc(100vh - 120px)",
                          overflowY: "auto",
                          color: "#fff",
                          width: "100%",
                          padding: "5px",
                        }
                      : {
                          height: "calc(100vh - 120px)",
                          overflowY: "auto",
                          display: "none",
                          color: "black",
                          padding: "5px",
                        }
                  }
                >
                  {pendingUser?.map((pendingUser: any, index) => (
                    <ListGroup.Item
                      as="li"
                      variant="light"
                      className={
                        activeClass == pendingUser.id ||
                        localUser == pendingUser.id
                          ? "d-flex justify-content-between align-items-start active  w-100"
                          : "d-flex justify-content-between align-items-start w-100"
                      }
                      style={
                        activeClass == pendingUser.id ||
                        localUser == pendingUser.id
                          ? {
                              marginBottom: "5px",
                              borderRadius: "10px",
                              backgroundColor: "#007bff30",
                              border: "2px solid #007bff",
                            }
                          : { marginBottom: "5px", borderRadius: "10px" }
                      }
                      key={index}
                      onClick={() => selectUser(pendingUser)}
                    >
                      <span
                        className="profileImage"
                        style={{
                          fontFamily: "Arial, Helvetica, sans-serif",
                          width: "3rem",
                          height: "3rem",
                          borderRadius: "50%",
                          background: " #ffb600",
                          fontSize: "1rem",
                          color: "#000",
                          textAlign: "center",
                          lineHeight: "3rem",
                          display: "inline-table",
                          margin: "0px",
                          marginRight: "5px",
                        }}
                      >
                        {pendingUser.user_name
                          ? (decryptMessage(pendingUser.user_name) || "")
                              .split(" ")
                              .map((user_name: any) => user_name[0])
                              .join("")
                              .toUpperCase()
                          : ""}
                      </span>
                      <div
                        className="namewrapper"
                        style={{
                          marginRight: "auto",
                          marginLeft: "0.5rem",
                          color: "black",
                        }}
                      >
                        <div className="fw-bold">
                          {decryptMessage(pendingUser.user_name)}
                        </div>
                        {pendingUser?.last_message == "image" ? (
                          <label>Image</label>
                        ) : pendingUser?.last_message == "PDF" ? (
                          <label>PDF</label>
                        ) : decryptMessage(pendingUser?.last_message || "")
                            ?.length ? (
                          decryptMessage(pendingUser?.last_message || "")!
                            .length >= 30 ? (
                            decryptMessage(
                              pendingUser?.last_message || ""
                            )!.substring(0, 30) + "..."
                          ) : (
                            decryptMessage(pendingUser?.last_message || "")
                          )
                        ) : (
                          decryptMessage(pendingUser?.last_message || "")
                        )}
                      </div>
                      <div
                        style={styles.timestamp}
                        className="timestamp"
                        key={index + 5}
                      >
                        <span>
                          {moment(pendingUser.last_message_timestamp).fromNow()}
                        </span>
                        <UnreadCount
                          key={index + 2001}
                          userid={pendingUser.id}
                        ></UnreadCount>
                      </div>
                    </ListGroup.Item>
                  ))}
                </ListGroup>

                <ListGroup
                  id="idle-group"
                  as="ul"
                  key={2}
                  style={
                    active === "idle" || activeMenu == "idle"
                      ? {
                          height: "calc(100vh - 120px)",
                          overflowY: "auto",
                          color: "#fff",
                          width: "100%",
                          padding: "5px",
                        }
                      : {
                          height: "calc(100vh - 120px)",
                          overflowY: "auto",
                          display: "none",
                          color: "black",
                          padding: "5px",
                        }
                  }
                >
                  {idleUser.map((idleUse: any, index) => (
                    <ListGroup.Item
                      as="li"
                      className={
                        activeClass == idleUse.id || localUser == idleUse.id
                          ? "d-flex justify-content-between align-items-start active  w-100"
                          : "d-flex justify-content-between align-items-start w-100"
                      }
                      style={
                        activeClass == idleUse.id || localUser == idleUse.id
                          ? {
                              marginBottom: "5px",
                              borderRadius: "10px",
                              backgroundColor: "#007bff30",
                              border: "2px solid #007bff",
                            }
                          : { marginBottom: "5px", borderRadius: "10px" }
                      }
                      key={index}
                      onClick={() => selectUser(idleUse)}
                    >
                      <span
                        className="profileImage"
                        style={{
                          fontFamily: "Arial, Helvetica, sans-serif",
                          width: "3rem",
                          height: "3rem",
                          borderRadius: "50%",
                          background: " #ffb600",
                          fontSize: "1rem",
                          color: "#000",
                          textAlign: "center",
                          lineHeight: "3rem",
                          display: "inline-table",
                          margin: "0px",
                          marginRight: "5px",
                        }}
                      >
                        {idleUse.user_name
                          ? (decryptMessage(idleUse.user_name) || "")
                              .split(" ")
                              .map((user_name) => user_name[0])
                              .join("")
                              .toUpperCase()
                          : ""}
                      </span>
                      <div
                        className="namewrapper"
                        style={
                          active === "idle" || activeMenu == "idle"
                            ? {
                                marginRight: "auto",
                                marginLeft: "0.5rem",
                                color: "black",
                              }
                            : { color: "white" }
                        }
                      >
                        <div className="fw-bold">
                          {decryptMessage(idleUse.user_name)}
                        </div>
                        {idleUse?.last_message == "image" ? (
                          <label>Image</label>
                        ) : idleUse?.last_message == "PDF" ? (
                          <label>PDF</label>
                        ) : idleUse?.last_message ? (
                          decryptMessage(idleUse.last_message)?.length ??
                          0 >= 25 ? (
                            decryptMessage(idleUse.last_message)!.substring(
                              0,
                              25
                            ) + "..."
                          ) : (
                            decryptMessage(idleUse.last_message)!
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        style={styles.timestamp}
                        className="timestamp"
                        key={index + 5}
                      >
                        <span>
                          {moment(idleUse.last_message_timestamp).fromNow()}
                        </span>
                        <UnreadCount
                          key={index + 2001}
                          userid={idleUse.id}
                        ></UnreadCount>
                      </div>
                      {/* <span className='user-name' key={index+1}>{ idleUse.user_name}</span>
                              <span key={index} className="last-message">{decryptMessage(idleUse.last_message)}</span>
                              <span className="timestamp" key={index+5}>{moment(idleUse.last_message_timestamp).fromNow() }</span>  */}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </div>
            </Col>
          </Col>
          <Col xs={8} md={8} style={{ padding: 0 }}>
            <Chats tabActive={active} />
            {/* <ListUser setChatUser={chatUser}   activeUser={activeClass} messages={msgs} myuser={myuser}/> */}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default UserList;
